import Facebook from '@src/components/icons/socials/facebook';
import Twitter from '@src/components/icons/socials/twitter';
import Instagram from '@src/components/icons/socials/instagram';
import LinkedIn from '@src/components/icons/socials/linkedin';
import WhatsApp from '@src/components/icons/socials/whatsapp';
import Telegram from '@src/components/icons/socials/telegram';
import Discord from '@src/components/icons/socials/discord';
import Email from '@src/components/icons/socials/email';
export const banner = {
  title: 'Build a credible presence with your DRep Profile',
  description:
    'Create a unique, URL-friendly DRep ID with flexibility to edit and manage profile data.',
  features: ['100% Secure & Private', 'Unique, URL-Friendly Identity'],
};

export const features = [
  {
    subTitle: 'Create desired Drep Id',
    title: 'Unique, URL-Friendly Identities',
    description:
      'Create a unique , memorable URL that represents you. Simplify profile management.',
    keySentence: 'Unique, URL-Friendly Identity',
    image: '/features/feature-1.png',
  },
  {
    subTitle: 'Delegate with Confidence',
    title: 'Delegate with Confidence',
    description:
      "Chose a trusted representative to vote on your behalf. Delegate DRep's based on their expertise and track record.",
    keySentence: '100% Secure & Private',
    image: '/features/feature-2.png',
  },
  {
    subTitle: 'Social Sharing',
    title: 'Social Sharing',
    description:
      ' Easily share your DRep identity across social platforms. Enhance discoverability and build connections effortlessly.',
    keySentence: 'Simplified Social Sharing',
    image: '/features/feature-3.png',
  },
];

export const getStartedTitle =
  'Ready To Create Your DRep ID? Get Started Today!';

export const noContentDescription = {
  owner: {
    info: 'Looks like you haven’t added anything to your profile yet! Add them for more visibility.',
    other:
      'There is no data to be displayed. Looks like you haven’t added anything to your profile yet!',
  },
  user: 'There is no data to be displayed.  This may be because no content has been added yet.',
};

export const toolTipDescription = {
  retireDRep:
    'Delegators will still see your status as retired in the DRep directory and can re-delegate their votes to another DRep. You can re-enter by delegating your personal voting power to another DRep or by coming out of retirement and resuming your previous role. If you choose to come out of retirement, your DRep ID and voting power will revert to the state before retirement, including your Ada balance and any delegated power.',
  liveVotingPower:
    "Live Voting Power estimates a DRep's voting influence based on their current delegators but may not be precise due to delays in reflecting changes in delegators' wallet balance.",
  influencePower:
    'Influence Power represents the proportion of total active voting power held by a DRep. It is calculated by dividing the DRep’s total active voting power by the total ADA delegated to all DReps at the end of the previous epoch.',
  submissionDate: 'The date when the governance action was submitted on-chain.',
  expiryDate:
    'The date when the governance action will expiry if it doesn’t reach ratification thresholds.<br><br>IMPORTANT: If the governance action is ratified before the expiry date it will be considered ratified and it will not be available to vote on afterwards.',
};

export const fourOFour = {
  title: '404 : Error',
  description:
    "The page you were trying to reach couldn't be found on the server. Please check your network connection.",
};

export const fiveOThree = {
  title: '503: Service Unavailable',
  description: 'The server is temporarily busy. Try again later.',
};

export const socialShareIcons = [
  {
    icon: Facebook,
    label: 'Facebook',
  },
  {
    icon: Twitter,
    label: 'X/Twitter',
  },
  {
    icon: Instagram,
    label: 'Instagram',
  },
  {
    icon: LinkedIn,
    label: 'LinkedIn',
  },
  {
    icon: WhatsApp,
    label: 'Whatsapp',
  },
  {
    icon: Telegram,
    label: 'Telegram',
  },
  {
    icon: Discord,
    label: 'Discord',
  },
  {
    icon: Email,
    label: 'Email',
  },
];

export const faq = {
  title: 'FAQ',
  questions: [
    {
      question: 'How do I create Drep ID?',
      answer:
        'You can create a DRep ID by logging in on our platform, and following the guided steps in your dashboard.',
    },
    {
      question: 'Why do I need to use a DRep ID?',
      answer:
        'A DRep ID provides a short and unique identity for id lookup and social sharing. You can change your underlying drep-id without losing your DrepID token',
    },
    {
      question: 'How can I test it?',
      answer:
        'You can use testnet version id Drep.id and mint token with test ADA.',
    },
    {
      question: 'Can I  associate my  DrepID  with another wallet?',
      answer:
        'It will be possible to associate your DrepID with different wallet. Stay tuned for updates. Contact us if this you want us to prioritize this feature,',
    },
  ],
};

export const socialLinks = [
  {
    icon: Facebook,
    href: 'https://www.facebook.com/siretotechnology',
  },
  {
    icon: Twitter,
    href: 'https://x.com/SiretoTech',
  },
  {
    icon: LinkedIn,
    href: 'https://www.linkedin.com/company/sireto-technology/',
  },
];

export const getStarted = {
  title: 'Ready to own your unique representative name?',
  description:
    'Join DRepID today to create your unique token identity and get involved in the  governance processes that is shaping the future.',
};

export const howItWorks = {
  title: 'Your gateway to seamless representative profile management',
  description:
    'Follow these steps to create a unique, URL-friendly identity and take control of your decentralized voting experience.',
  steps: [
    {
      id: 1,
      title: 'Connect your Wallet',
      description:
        'Connect effortlessly with your preferred wallet to the DRep platform. Enjoy decentralized, non-custodial identity management of your DRep ID Account.',
    },
    {
      id: 2,
      title: 'Register your Unique DRep ID',
      description:
        "Choose a unique, URL-friendly DRep ID for effortless recognition. It's like a username on the blockchain, making your DRep ID easy to share.",
    },
    {
      id: 3,
      title: 'Customizable DRep Profile',
      description:
        'Your personalized DRep profile page is created. It displays your chosen DRep ID, and other crucial information. Make your identity recognizable and trusted within your community.',
    },
  ],
};

export const GOVERNANCE_ACTIONS_FILTERS = [
  {
    key: 'NoConfidence',
    label: 'No Confidence',
  },
  {
    key: 'NewCommittee',
    label: 'New Committee',
  },
  {
    key: 'NewConstitution',

    label: 'Update to the Constitution',
  },
  {
    key: 'HardForkInitiation',

    label: 'Hard Fork',
  },
  {
    key: 'ParameterChange',

    label: 'Protocol Parameter Change',
  },
  {
    key: 'TreasuryWithdrawals',
    label: 'Treasury Withdrawal',
  },
  {
    key: 'InfoAction',
    label: 'Info Action',
  },
];

export const SECURITY_RELEVANT_PARAMS_MAP: Record<string, string> = {
  maxBlockBodySize: 'max_block_size',
  maxTxSize: 'max_tx_size',
  maxBlockHeaderSize: 'max_bh_size',
  maxValueSize: 'max_val_size',
  maxBlockExecutionUnits: 'max_block_ex_mem',
  txFeePerByte: 'min_fee_a',
  txFeeFixed: 'min_fee_b',
  utxoCostPerByte: 'coins_per_utxo_size',
  govActionDeposit: 'gov_action_deposit',
  minFeeRefScriptCostPerByte: 'min_fee_ref_script_cost_per_byte',
};

export const PPU_NETWORK_GROUP_PARAMS_KEYS = [
  'max_block_size',
  'max_tx_size',
  'max_bh_size',
  'max_val_size',
  'max_tx_ex_mem',
  'max_tx_ex_steps',
  'max_block_ex_mem',
  'max_block_ex_steps',
  'max_collateral_inputs',
];

export const PPU_ECONOMIC_GROUP_PARAMS_KEYS = [
  'min_fee_a',
  'min_fee_b',
  'min_fee_ref_script_cost_per_byte',
  'pool_deposit',
  'drep_deposit',
  'key_deposit',
  'monetary_expand_rate',
  'min_pool_cost',
  'treasury_growth_rate',
];

export const PPU_TECHNICAL_GROUP_PARAMS_KEYS = [
  'influence',
  'max_epoch',
  'optimal_pool_count',
  'cost_model_id',
];

export const PPU_GOVERNANCE_GROUP_PARAMS_KEYS = [
  'dvt_committee_no_confidence',
  'dvt_committee_normal',
  'dvt_hard_fork_initiation',
  'dvt_motion_no_confidence',
  'dvt_p_p_economic_group',
  'dvt_p_p_gov_group',
  'dvt_p_p_network_group',
  'dvt_p_p_technical_group',
  'dvt_treasury_withdrawal',
  'dvt_update_to_constitution',
  'dvt_motion_no_confidence',
  'gov_action_lifetime',
  'gov_action_deposit',
  'drep_activity',
  'committeeMinSize',
  'committeeMaxTermLength',
];

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

import React from 'react';

export default function Facebook(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18 11C18 6.6 14.4 3 10 3C5.6 3 2 6.6 2 11C2 15 4.9 18.3 8.7 18.9V13.3H6.7V11H8.7V9.2C8.7 7.2 9.9 6.1 11.7 6.1C12.6 6.1 13.5 6.3 13.5 6.3V8.3H12.5C11.5 8.3 11.2 8.9 11.2 9.5V11H13.4L13 13.3H11.1V19C15.1 18.4 18 15 18 11Z"
        fill="currentColor"
      />
    </svg>
  );
}

import React from 'react';

export default function Telegram(props: React.SVGAttributes<{}>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.48905 11.5562L11.9201 14.3202C12.8208 15.3442 13.2712 15.8562 13.7425 15.7315C14.2139 15.6069 14.3756 14.9331 14.6989 13.5853L16.4922 6.10942C16.9901 4.03374 17.239 2.9959 16.6856 2.484C16.1323 1.9721 15.173 2.35294 13.2546 3.11461L4.35407 6.64837C2.8197 7.25755 2.05251 7.56217 2.0038 8.0856C1.99882 8.13915 1.99873 8.19307 2.00356 8.24662C2.05066 8.77027 2.81691 9.07747 4.34941 9.69172C5.04379 9.97005 5.39098 10.1092 5.6399 10.3758C5.66789 10.4057 5.6948 10.4368 5.72059 10.4688C5.94994 10.7538 6.04782 11.1278 6.24356 11.8758L6.6099 13.2757C6.80038 14.0036 6.89562 14.3676 7.14506 14.4172C7.3945 14.4668 7.61169 14.165 8.04605 13.5614L9.48905 11.5562ZM9.48905 11.5562L9.2507 11.3078C8.97943 11.0251 8.84383 10.8838 8.84383 10.7081C8.84383 10.5325 8.97943 10.3911 9.2507 10.1083L11.9305 7.31557"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
